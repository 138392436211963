* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html,
body {
  width: 100%;
  height: 100%;
  background: #f3f8ff;
  color: #000;
  font-size: 13px; }
  @media (min-width: 540px) {
    html,
    body {
      font-size: 16px; } }

body {
  font-family: "Open Sans", Helvetica, Arial, sans-serif; }

img {
  max-width: 100%; }

input,
textarea,
keygen,
select,
button,
a {
  outline-width: 0; }
  .showfocus input:focus, .showfocus
  textarea:focus, .showfocus
  keygen:focus, .showfocus
  select:focus, .showfocus
  button:focus, .showfocus
  a:focus {
    outline-width: 5px; }

a {
  color: #436473;
  text-decoration: none;
  position: relative; }
  a:before {
    content: '';
    position: absolute;
    height: 1px;
    background: #436473;
    width: 100%;
    left: 0;
    bottom: -2px;
    transition: transform 0.2s ease-out; }
  a:focus:before, a:hover:before {
    transition: transform 0.2s ease-in;
    transform: scaleX(0); }

sup {
  font-size: 0.5em; }

svg {
  width: 100%;
  height: 100%; }

svg,
path {
  fill: currentColor; }

.hidden {
  display: none;
  pointer-events: none; }

.clearfix:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0; }

.section {
  padding: 20px;
  position: relative;
  background-color: #000; }

.text-uppercase {
  text-transform: uppercase; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.logo {
  margin: 10px auto; }
  @media (min-width: 720px) {
    .logo {
      float: left; } }
  .logo span {
    position: absolute;
    text-indent: -9999px; }

.logo-beta {
  position: relative; }
  .logo-beta span {
    color: #f9bc60;
    position: absolute;
    right: 15px;
    top: 100%;
    text-transform: uppercase;
    font-weight: 500; }

.loader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0 40px 0 #fff;
  position: relative;
  animation: loader11 0.5s ease-in-out alternate infinite;
  animation-delay: 0.2s;
  top: -40px;
  margin: 0 auto; }
  .loader::after, .loader::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0 40px 0 #fff;
    animation: loader11 0.5s ease-in-out alternate infinite; }
  .loader::before {
    left: -32px;
    animation-delay: 0.3s; }
  .loader::after {
    right: -32px;
    animation-delay: 0.1s; }

@keyframes loader11 {
  0% {
    box-shadow: 0 40px 0 #fff; }
  100% {
    box-shadow: 0 20px 0 #fff; } }

.overlay {
  position: fixed;
  top: 0;
  z-index: 2;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  display: none;
  opacity: 0;
  transition: opacity 0.15s ease-in; }
  .overlay.entering {
    display: block; }
  .overlay.entered {
    opacity: 1; }

.overlay-form {
  position: fixed;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: none;
  width: 100%;
  padding: 20px; }
  .overlay-form.entering {
    display: block; }
  @media (min-width: 520px) {
    .overlay-form {
      width: 500px; } }
  .overlay-form__logo, .overlay-form__input-container, .overlay-form__submit {
    transition: opacity 0.25s ease-in, transform 0.25s ease-in;
    display: block;
    margin: 0 auto;
    transform: translateY(10px);
    opacity: 0; }
    .entered .overlay-form__logo, .entered .overlay-form__input-container, .entered .overlay-form__submit {
      opacity: 1;
      transform: translateY(0); }
  .overlay-form__input-feedback {
    margin-top: 0.5em; }
  .overlay-form__logo {
    width: 210px;
    height: 36px;
    margin-bottom: 5em; }
    .overlay-form__logo img {
      width: 100%;
      height: 100%; }
  .overlay-form__input-container {
    margin-bottom: 3em;
    transition-delay: 0.3s; }
  .overlay-form__submit {
    transition-delay: 0.375s; }
  .overlay-form__success,
  .overlay-form .loader {
    display: none;
    position: relative;
    top: -150px; }
  .overlay-form__success p {
    top: -50px;
    position: relative; }
  .overlay-form--loading .overlay-form__input-container,
  .overlay-form--loading .overlay-form__submit {
    transition: opacity 0.1s ease-out;
    opacity: 0;
    pointer-events: none; }
  .overlay-form--loading .loader {
    display: block; }
  .overlay-form--success .overlay-form__input-container,
  .overlay-form--success .overlay-form__submit {
    opacity: 0;
    pointer-events: none; }
  .overlay-form--success .overlay-form__success {
    display: block; }

button,
.button {
  border: 0;
  background: #fff;
  color: #000;
  padding: 20px;
  text-transform: uppercase;
  letter-spacing: 0.28em;
  font-weight: bold;
  font-size: 1em;
  border-radius: 2px;
  cursor: pointer; }

a,
button,
.button {
  position: relative; }
  a:active,
  button:active,
  .button:active {
    top: 1px; }

button {
  transition: background 0.12s ease-in, color 0.12s ease-in; }
  button:focus, button:hover {
    background: #000;
    color: #fff; }

.container {
  max-width: 1440px;
  margin: 0 auto; }

.button--outline {
  background: none;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 5px;
  transition: border-color 0.12s ease-in, color 0.12 ease-in; }
  .button--outline:focus, .button--outline:hover {
    background: none;
    border: 2px solid #d5f1f1;
    color: #d5f1f1; }

.button--gradient, .button--gradient:focus, .button--gradient:hover {
  background-image: linear-gradient(to right, #D0509B 0%, #F9BC60 30%, #937CFD 75%, #68C0DD 100%); }

.button--gradient span {
  transition: color 0.12s ease-in;
  position: relative;
  z-index: 2; }

.button--gradient:before {
  content: "";
  background: #fff;
  position: absolute;
  margin: 3px;
  top: 0;
  left: 0;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  transition: background 0.12s ease-in; }

.button--gradient:focus span, .button--gradient:hover span {
  color: #fff; }

.button--gradient:focus:before, .button--gradient:hover:before {
  background: #000; }

[type="email"],
[type="text"] {
  border-radius: 2px;
  background: #fff;
  font-size: 1.2em;
  padding: 20px 10px;
  border: 0;
  width: 100%; }

@keyframes tv-flicker {
  0%, 50%, 60%, 80% {
    opacity: 1; }
  10%, 40%, 55%, 70%, 90% {
    opacity: 0; }
  20%, 65%, 76%, 85%, 95% {
    opacity: 0.4; } }

@keyframes easeOutBounceDown {
  0% {
    transform: scaleY(0.8);
    -webkit-animation-timing-function: ease-in; }
  33% {
    transform: scaleY(0);
    -webkit-animation-timing-function: ease-out; }
  50% {
    transform: scaleY(0.16);
    -webkit-animation-timing-function: ease-in; }
  66% {
    transform: scaleY(0);
    -webkit-animation-timing-function: ease-out; }
  82% {
    transform: scaleY(0.04);
    -webkit-animation-timing-function: ease-in; }
  92% {
    transform: scaleY(0);
    -webkit-animation-timing-function: ease-out; }
  97% {
    transform: scaleY(0.02);
    -webkit-animation-timing-function: ease-in; }
  100% {
    transform: scaleY(0); } }

body {
  border: 10px solid #fff;
  padding: 0 10px;
  height: auto;
  min-height: 100%; }

.main-header {
  text-align: center;
  margin: 1em 0; }
  .main-header__logo {
    vertical-align: middle;
    width: 80px;
    margin: 0 auto;
    display: block; }
    @media (min-width: 540px) {
      .main-header__logo {
        display: inline-block;
        margin: 0; } }
  .main-header sup a {
    color: #000; }
    .main-header sup a:before {
      display: none; }

.video-preview {
  margin: 1em auto;
  padding: 0 1em;
  opacity: 0;
  transition: opacity 0.5s ease-out;
  max-width: 850px; }
  @media (min-height: 800px) {
    .video-preview {
      max-width: 1000px; } }
  .video-preview video {
    width: 100%;
    max-width: 100%;
    border: 1px solid #eaeaea; }
  body.no-js .video-preview, .video-preview--active {
    transition: opacity 0.5s ease-in;
    opacity: 1; }

.footnote {
  text-align: center;
  margin: 3em 0 1em;
  opacity: 0;
  transition: opacity 0.2s ease-out; }
  body.no-js .footnote, .footnote--active {
    opacity: 1;
    transition: opacity 0.2s ease-in; }
  .footnote small {
    display: block;
    margin: 1em 0; }

.extensions {
  display: flex;
  justify-content: center; }

.browser {
  text-align: center;
  margin-right: 3em;
  transform: scale(0.7);
  opacity: 0;
  transition: opacity 0.2s ease-out, transform 0.3s ease-out; }
  body.no-js .browser, .browser--active {
    transform: scale(1);
    opacity: 1;
    transition: opacity 0.2s ease-in, transform 0.3s ease-in; }
  @media (min-width: 400px) {
    .browser {
      margin-right: 5em; } }
  .browser:last-child {
    margin-right: 0; }
  .browser__link {
    display: block;
    width: 60px; }
    @media (min-width: 400px) {
      .browser__link {
        width: auto; } }
  .browser__logo {
    width: 100px;
    display: block;
    margin: 0 auto 1em; }
